/*
 * 업무구분: 신탁>파일재전송>전자문서재전송
 * 화 면 명: MSPTS330M
 * 화면설명: 전자문서재전송
 * 작 성 일: 2023.10.16
 * 작 성 자: 유동훈
 */
<template>
    <ur-page-container
      class="fts"
      :show-title="false"
      :title="pHeaderObj.title"
      :topButton="true"
    >
    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <!-- <div class="column w100p gap12">
            <div class="row w100p"> -->
              <div class="left">
                <div class="wrap-input row">
                  <label class="emphasis"> 처리기간 </label>
                  <mo-date-picker class="w130" disabled v-model="ds_search.cal_startDt" :bottom="false" ref="cal_startDt" :class="ds_error.cal_startDt ? 'error' : ''"/>
                  <i> ~ </i>
                  <mo-date-picker class="w130" disabled v-model="ds_search.cal_endDt"   :bottom="false" ref="cal_endDt"   :class="ds_error.cal_endDt   ? 'error' : ''"/>
                </div>
              </div>
              <div class="right">
                <div class="wrap-button row">
                  <mo-button @click="fn_init()"> 초기화 </mo-button>
                  <mo-button primary @click="fn_searchCvrList()"> 조회 </mo-button>
                  <mo-button primary @click="fn_tranCvrList()"> 처리 </mo-button>
                </div>
              </div>
            <!-- </div>
          </div> -->
        </div>
      </ur-box-container>


      <ur-box-container direction="column" alignV="start">                 
        <p class="desc02 mb-1"><em class="colorR">파일은 오류 시점부터 D+7까지만 보관됩니다.</em></p>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <tr>
                <th> <mo-checkbox class="vaBottom" v-model="isSelectAll" @input="fn_selectAllItems" /> </th>
                <th> 계좌번호     </th>
                <th> 고객명       </th>
                <th> 커버발행번호 </th>
                <th> 커버유형코드 </th>
                <th> 회사보관용   </th>
                <th> 고객보관용   </th>
                <th> 전송일시     </th>
                <th> 알림톡       </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in ds_searchList" :key="i">
                <td v-if="row.docCo != null && row.tranYn != 'Y' && row.todayYn == 'Y'"> <mo-checkbox v-model="row.checkYn" @input="fn_selectItem" /> </td>
                <td v-if="row.docCo == null || row.tranYn == 'Y' || row.todayYn != 'Y'"> </td>
                <td>{{row.acctNo       }}</td>
                <td>{{row.cnm          }}</td>
                <td>{{row.cvrIsueNo    }}</td>
                <td>{{row.cvrTypCNm    }}</td>
                <td>{{row.docCo        }}</td>
                <td>{{row.docCu        }}</td>
                <td>{{row.trtDtm       | dateForamt }}</td>
                <td v-if="row.docCu != null && row.tranYn == 'Y'"><mo-button @click="fn_sendTalk(row)">재발송</mo-button> </td>
                <td v-if="row.docCu == null || row.tranYn != 'Y'"> </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="wrap-pagination mt-3">
          <span class="float-left">
            총 {{ $bizUtil.numberWithCommas(ds_searchList.length) }} 건
          </span>
        </div>
		  </ur-box-container>
    </div>
    <!-- //.fts-main -->
    
    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil    from '~/src/ui/ts/comm/TSCommUtil'
  import TSComDate     from '~/src/ui/ts/comm/TSComDate'
  import TSDataSet     from '~/src/ui/ts/dts/TSDataSet'          // dataSet 정리
  import TSHeader      from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
  import TSAlertPopup  from '~/src/ui/ts/comm/TSAlertPopup'      // Alert 팝업 (공통)

  import FSFileUtil    from "~/src/ui/fs/comm/FSFileUtil"
  import FSDocUtil     from "~/src/ui/fs/comm/FSDocUtil"

  
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name       : "MSPTS330M",
    screenId   : "MSPTS330M",
    components : {
      'ts-header'      : TSHeader,
      'ts-alert-popup' : TSAlertPopup,
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.$nextTick(()=>{
        this.fn_searchCvrList()
      })
    },
    mounted() {
      this.fn_create()
      this.$bizUtil.insSrnLog('MSPTS330M')
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {
          title : "문서재전송",
          step  : "",
        },
        
        pAlertPopupObj: {},  // 팝업 객체

    	  ds_cvrList    : [],  // 커버목록
    	  ds_docList    : [],  // 문서목록
    	  ds_searchList : [],  // 검색목록
    	  ds_tranList   : [],  // 전송목록
        ds_search     : {},  // 문서검색조건

        isSelectAll   : false,
        isSuccessAll  : false,
        ds_error            : {
          cal_startDt : false,
          cal_endDt   : false,
        },

        ds_basInfo            : this.getStore("tsStore").getters.getBasInfo.data,

        // EAI(PO) 호출 객체
        eaiCommObj : TSCommUtil.gfn_eaiCommObj(),
        g_eaiId    : "",
      };
    },

    /***********************************************************************************
     * filters 함수 정의 영역                                                         *
     ***********************************************************************************/
    filters: {
      dateForamt(val) {
        if ( TSCommUtil.gfn_isNull(val) ) return ""
        return val.substr(0, 4) + "-" + val.substr(4, 2) + "-" + val.substr(6, 2)
      }
    },

    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_create
       * 설명       : 화면 생성
       ******************************************************************************/
      fn_create() {
        let busyDate = this.ds_basInfo.busyDate
        this.ds_search.cal_startDt = TSCommUtil.gfn_dateReplace(1, TSComDate.gfn_minusDate(busyDate, 7))
        this.ds_search.cal_endDt   = TSCommUtil.gfn_dateReplace(1, busyDate)

        console.log("this.ds_basInfo ==> ", this.ds_basInfo)
      },

      /******************************************************************************
       * Function명 : fn_init
       * 설명       : 초기화
       ******************************************************************************/
      fn_init() {
        this.ds_cvrList    = []
        this.ds_docList    = []
        this.ds_searchList = []
        this.ds_tranList   = []

        this.isSelectAll   = false
      },

      /******************************************************************************
       * Function명 : fn_searchCvrList
       * 설명       : 커버발행목록조회
       ******************************************************************************/
      fn_searchCvrList() {
        this.fn_init()

        this.ds_search.acctNo       = ""
        this.ds_search.fromBaseDate = TSCommUtil.gfn_replace(this.ds_search.cal_startDt, '-', '')
        this.ds_search.toBaseDate   = TSCommUtil.gfn_replace(this.ds_search.cal_endDt  , '-', '')
        this.ds_search.cvrIsueStatC = "99"
        this.ds_search.cvrTypC      = ""
        this.ds_search.teleVssTc    = ""
        this.ds_search.occuBrcd     = ""
        this.ds_search.procEmpno    = this.ds_basInfo.usid
//        this.ds_search.procEmpno    = "29731"

        this.g_eaiId = "C392_F10600269_S"
        this.eaiCommObj.lv_vm   = this
        this.eaiCommObj.auth    = "S"
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params             = this.ds_search

        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600269, this.fn_error)
      },

      /***************************************************************************************
       * Function명 : fn_callBack_F10600269
       * 설명       : 커버발행목록조회 후 콜백 
      *****************************************************************************************/
      fn_callBack_F10600269(res) {
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          if ( TSCommUtil.gfn_trim(resData.errorMsg) == '' ) {
            this.fn_AlertPopup(0,{content : resData.errorCode})
          } else {
            this.fn_AlertPopup(0,{content : resData.errorMsg})
          }
          return false
        }

        if ( resData.tfbz61550 == null || resData.tfbz61550.length == 0 ) {
          this.fn_AlertPopup(0,{content : "처리 대상이 없습니다."})
          return false
        }

        this.ds_cvrList = resData.tfbz61550
        this.fn_searchDocList()
      },

      /******************************************************************************
       * Function명 : fn_searchDocList
       * 설명       : 전자문서파일목록조회
       ******************************************************************************/
      fn_searchDocList() {
        let param = {
//          busnScCd : '06',
          busnScCd : 'TS',
        }
        FSFileUtil.gfn_searchDocumentList(this, param, this.fn_callBack_searchDocList)
      },

      /******************************************************************************
       * Function명 : fn_callBack_searchDocList
       * 설명       : 전자문서파일목록조회 후 콜백 
       ******************************************************************************/
      fn_callBack_searchDocList(res) { 

        // 미전송리스트 추출
        this.ds_searchList = []
        for ( let i = 0; i < this.ds_cvrList.length; i++ ) {
          if ( TSCommUtil.gfn_isNull(this.ds_cvrList[i].cvrIsueNo) ) {
            continue
          }
          if ( this.ds_cvrList[i].cvrIsueNo.substr(14, 1) != '9' ) {
            continue
          }

          let temp = this.ds_cvrList[i]
          temp.rowNo = this.ds_searchList.length
          this.ds_searchList.push(temp)
        }

        // 전자문서 적용
        let busyDate = this.ds_basInfo.busyDate
        this.ds_docList = res.dcfsEltrnSignDoctSendListVO
        for ( let i = 0; i < this.ds_searchList.length; i++ ) {
          let temp = this.ds_docList.filter(p=>p.eltrnDoctId == this.ds_searchList[i].cvrIsueNo)[0]
          if ( TSCommUtil.gfn_isNull(temp) ) {
            continue
          }

          this.ds_searchList[i].docCo    = (TSCommUtil.gfn_trim(temp.docCoYn).length == 0) ? null : "○"
          this.ds_searchList[i].docCu    = (TSCommUtil.gfn_trim(temp.docCuYn).length == 0) ? null : "○"
          this.ds_searchList[i].docPath  = (TSCommUtil.gfn_trim(temp.docPath).length == 0) ? null : temp.docPath
          this.ds_searchList[i].todayYn  = (temp.docPath.indexOf(busyDate) < 0)            ? null : "Y"
          this.ds_searchList[i].trtDtm   = (TSCommUtil.gfn_trim(temp.trtDtm ).length == 0) ? null : temp.trtDtm
          this.ds_searchList[i].tranYn   = (TSCommUtil.gfn_trim(temp.trtDtm ).length == 0) ? null : "Y"
        }        
        console.log(this.ds_searchList)
      },

      /******************************************************************************
       * Function명 : fn_tranCvrList
       * 설명       : 전송 처리 리스트 추출
       ******************************************************************************/
      fn_tranCvrList() {
        this.isSuccessAll = true
        this.ds_tranList = []
        for ( let i = 0; i < this.ds_searchList.length; i++ ) {
          if ( this.ds_searchList[i].checkYn == true ) {
            let temp = this.ds_searchList[i]
            temp.execNum = this.ds_tranList.length
            this.ds_tranList.push(JSON.parse(JSON.stringify(temp)))
          }
        }

        if ( this.ds_tranList.length == 0 ) {
            this.fn_AlertPopup(0,{content : "선택 항목이 없습니다."})
            return
        }

        this.fn_tran(0)
      },

      /******************************************************************************
       * Function명 : fn_tran
       * 설명       : 파일전송
       ******************************************************************************/
      fn_tran(row) {
        // 비동기 실행을 막기위해 수행
        this.fn_selectItem()
        if ( row >= this.ds_tranList.length ) {
          if ( this.isSuccessAll ) {
            window.vue.getStore('confirm').dispatch('ADD', "전자서식 전송처리 되었습니다.")
            this.fn_searchCvrList()
            return
          }
        }

        let obj = this.ds_tranList[row]
        this.fn_SerivceEltrnSignCmpltBatch(obj)

        // 알림톡전송
        // this.fn_sendTalk(obj)
      },

      /************************************************************************************************
        * Function명  : fn_SerivceEltrnSignCmpltBatch
        * 설명        : 전자서명 완료 처리
        ************************************************************************************************/
      fn_SerivceEltrnSignCmpltBatch(obj) {
        const pParams = {
          busnScCd            : "ts",
          eltrnDoctId         : obj.cvrIsueNo,
          eltrnDoctFilePathNm : obj.docPath,
          timeout             : '600000' // 기본 타이머 10분
        }

        const trnstId = 'txTSSFS02P7'
        this.post(this, pParams, trnstId, 'S')
          .then (response => {
            // 서버 데이터 가져오기
            if ( response.body !== null ) {
              if (response.body.trnsRsltCd == '0') { // 완료
                this.ds_searchList[obj.rowNo].checkYn = false
                this.fn_selectItem()

                this.fn_tran(obj.execNum + 1)
                return
              }

              window.vue.getStore('confirm').dispatch('ADD', obj.cnm + "의 [" + obj.cvrIsueNo + "] 전자서식 작성이 실패하였습니다. 업무보완바랍니다. (오류코드 : " + response.body.trnsRsltCd + ")")
            } else { // 정상처리가 아닌경우
              return response.msgComm.msgDesc
              this.fn_AlertPopup(0,{content : response.msgComm.msgDesc})
            }
          })
          .catch(function (error) {
            window.vue.getStore('progress').dispatch('UPDATE', false)
            window.vue.error(error)
          }
        )
      },

      /******************************************************************************
       * Function명 : fn_sendTalk
       * 설명       : 알림톡전송
       ******************************************************************************/
      async fn_sendTalk(obj) {
        let ds_sendData = await this.fn_getTalkData(obj)

        // 커버정보
        let cvrPblInfo = {}
        cvrPblInfo.cvrIsueNo = obj.cvrIsueNo
        this.getStore('tsStore').getters.getBasInfo.data.cvrPblInfo = cvrPblInfo

        // 고객정보
        let custInfo = {}
        custInfo = ds_sendData
        this.getStore('tsStore').getters.getBasInfo.data.custInfo = custInfo

        // 전송정보
        let sendMth = {}
        sendMth.celph = ds_sendData.celph
        this.getStore('tsStore').getters.getBasInfo.data.sendMth = sendMth

        // 문서정보
        let prdtObj = {}
        if ( obj.cvrTypC === 'A' ) {
          prdtObj.name = "신규계약"
          prdtObj.code = '06754' // 신규
        } else if (obj.cvrTypC === 'B') {
          prdtObj.name = obj.prsvtChngTcNm
          prdtObj.code = '06755' // 변경
        } else if (obj.cvrTypC === 'D') {
          prdtObj.name = "해지안내"
          prdtObj.code = '06756' // 해지
        }
        this.getStore('tsStore').getters.getBasInfo.data.prdtObj = prdtObj

        this.$bizUtil.tsUtils.mobpsPblProc(this).then(function(response) {
          if (response.body == null) {          
            window.vue.getStore('confirm').dispatch('ADD', '알림톡 재 발송 중 오류가 발생하였습니다.')
          } else {
            window.vue.getStore('confirm').dispatch('ADD', obj.cnm + '의 [' + obj.cvrIsueNo + ']의 알림톡을 재 발송하였습니다.')
          }
        }).catch(function(error){
          window.vue.error(error)
        })
      },

      /******************************************************************************
       * Function명 : fn_getTalkData
       * 설명       : 고객 또는 계약정보조회 - 알림톡 전화번호 및 상품명 확인 목적
       ******************************************************************************/
      async fn_getTalkData(obj) {
        let ds_search = {}
        ds_search.tacno        = obj.acctNo.substr( 0, 7)
        ds_search.bnkbSeq      = obj.acctNo.substr( 7, 3)
        ds_search.accSeq       = obj.acctNo.substr(10, 3)
        ds_search.ctno         = Number(ds_search.accSeq)
        ds_search.cnm          = obj.cnm

        let ds_sendData = {}
        if ( ds_search.bnkbSeq == "000" ) {
          let csId        = await this.fn_getCustId(ds_search.tacno)
          let ds_custInfo = await this.fn_getCustInfo(csId)

          ds_sendData.rcno    = ds_custInfo.rcno
          ds_sendData.cnm     = ds_custInfo.cnm
          ds_sendData.celph   = ds_custInfo.mvphBusiMnNo + ds_custInfo.mvphPofn + ds_custInfo.mvphSeq
        } else {
          let ds_contractInfo = await this.fn_getContractInfo(ds_search)

          ds_sendData.rcno    = ds_contractInfo.rcno
          ds_sendData.cnm     = ds_contractInfo.cnm
          ds_sendData.celph   = TSCommUtil.gfn_replace(ds_contractInfo.telNo, '-', '')
        }

        return ds_sendData
      },

      /******************************************************************************
       * Function명 : fn_getCustId
       * 설명       : 고객ID 조회
       ******************************************************************************/
      async fn_getCustId(tacno) {
        let ds_search = {}
        ds_search.tacno = tacno

        this.g_eaiId = "C392_F10600215_S"
        this.eaiCommObj.lv_vm   = this
        this.eaiCommObj.auth    = "S"
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params             = ds_search

        let res = await TSServiceUtil.invokeAsync(this.eaiCommObj)

        const resData = res.data
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          if ( TSCommUtil.gfn_trim(resData.errorMsg) == '' ) {
            this.fn_AlertPopup(0,{content : "고객ID 조회 오류 : " + resData.errorCode})
          } else {
            this.fn_AlertPopup(0,{content : resData.errorMsg})
          }
          return false
        }

        return resData.csId
      },

      /******************************************************************************
       * Function명 : fn_getCustInfo
       * 설명       : 고객정보 조회
       ******************************************************************************/
      async fn_getCustInfo(csId) {
        let ds_search = {}
        ds_search.agntCsId = csId

        this.g_eaiId = "C392_F10600113_S"
        this.eaiCommObj.lv_vm   = this
        this.eaiCommObj.auth    = "S"
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params             = ds_search

        let res = await TSServiceUtil.invokeAsync(this.eaiCommObj)

        const resData = res.data
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          if ( TSCommUtil.gfn_trim(resData.errorMsg) == '' ) {
            this.fn_AlertPopup(0,{content : "고객정보 조회 오류 : " + resData.errorCode})
          } else {
            this.fn_AlertPopup(0,{content : resData.errorMsg})
          }
          return false
        }

        let ds_result = resData
        return ds_result
      },

      /******************************************************************************
       * Function명 : fn_getContractInfo
       * 설명       : 계약정보 조회
       ******************************************************************************/
      async fn_getContractInfo(obj) {
        let ds_search = {}
        ds_search.reportMrdNm = "TS000001"
        ds_search.reportUrl   = "reportTFAC20000"
        ds_search.reportParam = JSON.stringify(obj)

        this.g_eaiId = "C392_F10600059_S"
        this.eaiCommObj.lv_vm   = this
        this.eaiCommObj.auth    = "S"
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params             = ds_search

        let res = await TSServiceUtil.invokeAsync(this.eaiCommObj)

        const resData = res.data
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          if ( TSCommUtil.gfn_trim(resData.errorMsg) == '' ) {
            this.fn_AlertPopup(0,{content : "계약정보 조회 오류 : " + resData.errorCode})
          } else {
            this.fn_AlertPopup(0,{content : resData.errorMsg})
          }
          return false
        }

        let ds_tfac20000Rst = JSON.parse(resData.reportParam).tfac20000Rst
        return ds_tfac20000Rst
      },

      /******************************************************************************
      * Function명 : fn_selectAllItems
      * 설명       : 리스트 전체 선택
      ******************************************************************************/      
      fn_selectAllItems() {
        for ( let i = 0; i < this.ds_searchList.length; i++ ) {
          if ( this.ds_searchList[i].docCo != null && this.ds_searchList[i].tranYn != 'Y' && this.ds_searchList[i].todayYn == 'Y') {
            this.ds_searchList[i].checkYn = this.isSelectAll
          }
        }        
      },
      
      /******************************************************************************
      * Function명 : fn_selectItem
      * 설명       : 선택
      ******************************************************************************/      
      fn_selectItem() {
        let selectAll = true
        for ( let i = 0; i < this.ds_searchList.length; i++ ) {
          if ( this.ds_searchList[i].tranYn == 'Y' ) {
            continue
          }
          if ( TSCommUtil.gfn_isNull(this.ds_searchList[i].docCo) ) {
            continue
          }
          if ( this.ds_searchList[i].checkYn != true ) {
            selectAll = false
            break
          }
        }
      
        this.isSelectAll = selectAll
        this.$forceUpdate()
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup(type, pPopupObj) {
        if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
          this.pAlertPopupObj.content = pPopupObj.content          
          this.pAlertPopupObj.confirm = false
          this.pAlertPopupObj.cancel = true
          this.pAlertPopupObj.type = 0
          this.pAlertPopupObj.contentTitle = ''

          if( !pPopupObj.cancel ) {
            this.pAlertPopupObj.cancel = pPopupObj.cancel
          }
          if( pPopupObj.confirm ) {
            this.pAlertPopupObj.confirm = pPopupObj.confirm
            this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
            this.pAlertPopupObj.confirmData = pPopupObj.confirmData
          }
          if( pPopupObj.closeFunc ) {
            this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
            this.pAlertPopupObj.closeData = pPopupObj.closeData
          }
          if( !TSCommUtil.gfn_isNull(type) && type != 0 ) {
            this.pAlertPopupObj.type = type
          }
          if( pPopupObj.contentTitle ) {
            this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle
          }
        }
        this.$refs.alertPopup.fn_Open()
      },
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    }
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
